export interface PerhitunganDataModel {
  [key: string]: unknown
  departure: string
  orderNumber: string
  orderStatus: string
  passenger: string
  pax: number
  pnr: string
  pricePerPax: number
}

export interface JumlahPaxDataModel {
  materialization: string
  minimumPax: string
}

export interface SummaryTableDataModel {
  [key: string]: unknown
  description: string
  quantity: number
  remark: string
  sub_total: number
  type: string
  unit_price: number
}

export interface PelunasanDataModel {
  bookingFeeTotal: number
  downPaymentTotal: number
  pax: number
  totalSettlement: number

}

export interface TablePenumpangDataModel {
  [key: string]: unknown
  id: number
  number: number
  name: string
  jenis_kelamin: string
  tanggal_lahir: string
  passpor: string
  tanggal_habis_berlaku: string
}

export interface PerhitunganPelunasanModel {
  perhitungan: PerhitunganDataModel[]
  jumlahPax: JumlahPaxDataModel
  summaryTable: {
    data: SummaryTableDataModel[]
    error: boolean
    message: string
    reducePassengers: number
  }
  pelunasan: PelunasanDataModel
  tablePesanan: PerhitunganDataModel[]
  tablePenumpang: TablePenumpangDataModel[]
  perhitungan_2: PerhitunganDataModel[]
  summaryTable_2: SummaryTableDataModel[]
  denda: SummaryTableDataModel[]
  pelunasan_2: PelunasanDataModel
}

export enum PerhitunganPelunasanActionType {
  PostPerhitunganList = '⌘➝App➝Perhitungan-Pelunasan➝PostPerhitunganPelunasan',
  GetJumlahPax = '⌘➝App➝Perhitungan-Pelunasan➝GetJumlahPax',
  PostSummaryTable = '⌘➝App➝Perhitungan-Pelunasan➝PostSummaryTable',
  GetPelunasan = '⌘➝App➝Perhitungan-Pelunasan➝GetPelunasan',
  PostTablePesanan = '⌘➝App➝Perhitungan-Pelunasan➝PostTablePesanan',
  PostTablePenumpang = '⌘➝App➝Perhitungan-Pelunasan➝PostTablePenumpang',
  PostPerhitunga2List = '⌘➝App➝Perhitungan-Pelunasan➝PostPerhitunganPelunasan2',
  PostSummaryTable2 = '⌘➝App➝Perhitungan-Pelunasan➝PostSummaryTable2',
  GetPelunasan2 = '⌘➝App➝Perhitungan-Pelunasan➝GetPelunasan2',
  PostDendaTable = '⌘➝App➝Perhitungan-Pelunasan➝PostDendaTable'
}

export type PerhitunganPelunasanAction = {
  data: {
    data: SummaryTableDataModel[]
    error: boolean
    message: string
    reducePassengers: number
  }
  type: PerhitunganPelunasanActionType.PostSummaryTable
} | {
  data: JumlahPaxDataModel
  type: PerhitunganPelunasanActionType.GetJumlahPax
} | {
  data: PelunasanDataModel
  type: PerhitunganPelunasanActionType.GetPelunasan
} | {
  data: PelunasanDataModel
  type: PerhitunganPelunasanActionType.GetPelunasan2
} | {
  data: PerhitunganDataModel[]
  type: PerhitunganPelunasanActionType.PostPerhitunga2List
} | {
  data: PerhitunganDataModel[]
  type: PerhitunganPelunasanActionType.PostPerhitunganList
} | {
  data: PerhitunganDataModel[]
  type: PerhitunganPelunasanActionType.PostTablePesanan
} | {
  data: SummaryTableDataModel[]
  type: PerhitunganPelunasanActionType.PostDendaTable
} | {
  data: SummaryTableDataModel[]
  type: PerhitunganPelunasanActionType.PostSummaryTable2
} | {
  data: TablePenumpangDataModel[]
  type: PerhitunganPelunasanActionType.PostTablePenumpang
};
